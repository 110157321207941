import * as React from 'react'
// import { Link } from 'gatsby'

const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>
      <h1>404 not found</h1>
    </main>
  )
}

export default NotFoundPage
